import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardMedia from '@mui/material/CardMedia'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Unstable_Grid2'
import type { FC } from 'react'

import aggregateMaps from './aggregate-maps.webp'
import boundaryMaps from './boundary-maps.webp'
import clusterMaps from './cluster-maps.webp'
import heatMaps from './heat-maps.webp'
import pointMaps from './point-maps.webp'

const usecases = [
  {
    text: 'Point maps',
    color: 'rgb(179, 246, 54)',
    image: pointMaps
  },
  {
    text: 'Cluster maps',
    color: 'rgb(255, 125, 137)',
    image: clusterMaps
  },
  {
    text: 'Heat maps',
    color: 'rgb(157, 152, 255)',
    image: heatMaps
  },
  {
    text: 'Boundary maps',
    color: 'rgb(156, 221, 220)',
    image: boundaryMaps
  },
  {
    text: 'Aggregate maps',
    color: 'rgb(255, 115, 46)',
    image: aggregateMaps
  }
]

export const MapstackUserCases: FC = () => {
  return (
    <Box
      sx={{
        p: 3,
        pt: '1px',
        pb: '1px'
      }}
    >
      <Grid
        container
        spacing={3}
        sx={{ justifyContent: 'center' }}
      >
        {usecases.map((usecase, index) => {
          return (
            <Grid
              sx={{ minWidth: 225 }}
              key={`Usecase:${index}`}
              md={2}
              xs={2}
            >
              <Card sx={{ bgcolor: usecase.color }}>
                <CardMedia
                  image={usecase.image.src}
                  sx={{ height: 202, bgcolor: 'neutral.50' }}
                />
                <Divider />
                <CardActions sx={{ justifyContent: 'center', fontWeight: 600 }}>
                  {usecase.text}
                </CardActions>
              </Card>
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
}
