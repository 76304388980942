import { Stack } from '@mui/material'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Popover from '@mui/material/Popover'
import type { FC } from 'react'
import { Scrollbar } from '~ui-components/components/atoms'
import { User, Workspace } from '~ui-components/types/__generated/gql/graphql'

type AccountPopoverUser = Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>

export type AccountPopoverWorkspace = Pick<Workspace, 'id' | 'name' | 'logo'>

export interface AccountPopoverProps {
  anchorEl: null | Element
  open: boolean
  user?: AccountPopoverUser
  workspaces?: AccountPopoverWorkspace[]
  slots?: any
  onClose?: () => void
}

export const AccountPopover: FC<AccountPopoverProps> = (props) => {
  const { open, anchorEl, onClose, slots: Slots } = props

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      disableScrollLock
      onClose={onClose}
      slotProps={{
        root: {},
        paper: {
          sx: {
            width: 240,
            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
            overflow: 'hidden',
            height: 'auto'
          }
        }
      }}
    >
      <Stack
        component={Scrollbar}
        flex={1}
        divider={<Divider />}
        sx={{
          height: '100%',
          maxHeight: 'calc(100dvh - 64px)'
        }}
      >
        <Box p={1}>
          <Slots.Workspaces />
        </Box>
        {Slots.Workspace && (
          <Box p={1}>
            <Slots.Workspace />
          </Box>
        )}
        <Box p={1}>
          <Slots.CommunityItem dropdown={false} />
        </Box>
        <Box p={1}>
          <Slots.UserProfile />
        </Box>
        <Stack p={1}>
          <Slots.LogoutButton />
        </Stack>
      </Stack>
    </Popover>
  )
}
