import { Container, Stack, Typography } from '@mui/material'
import {
  NumberSquareFive,
  NumberSquareFour,
  NumberSquareOne,
  NumberSquareThree,
  NumberSquareTwo
} from '@phosphor-icons/react'
import { Faq, Faqs } from '../Home/Section.6'

export function Section3() {
  return (
    <Container sx={{ py: 8 }}>
      <Stack
        direction='column'
        spacing={6}
      >
        <Typography
          variant='next:h2'
          component='h2'
          fontWeight={700}
        >
          Questions and answers
        </Typography>

        <Stack spacing={6}>
          <Faqs>
            <Faq
              startIcon={NumberSquareOne}
              question="How does Mapstack's pricing work?"
              answer='Mapstack charges on a per-seat basis. On the Free plan, all users can access the platform at no cost. For Team and Business plans, billing applies only to users with full workspace access. Guest users, anonymous viewers of public maps, or those accessing share links incur no additional fees. Charges are prorated—if a user joins partway through the billing cycle, you’ll only pay for the time they were active.'
            />

            <Faq
              startIcon={NumberSquareTwo}
              question='Is Mapstack free to use?'
              answer='Mapstack offers a variety of plans designed to support teams of all sizes. The Free plan allows individuals and small teams to get started with core features at no cost. It’s ideal for lightweight use or single users. Paid plans unlock enhanced functionality, designed for larger teams, departments, and organizations requiring more scale and capabilities.'
            />

            <Faq
              startIcon={NumberSquareThree}
              question='How much does Mapstack cost?'
              answer='The Free plan is completely free for individuals and small teams starting out. For teams who need more capacity and functionality, the Team plan costs $19 per user, per month (billed annually). Business and Enterprise plans are designed for larger organizations needing advanced features, scalability, and administrative controls. The Business plan is priced at $44 per user, per month (billed annually), while the Enterprise plan is custom-priced to fit the unique needs of the organization. Contact our sales team to learn more about Enterprise pricing.'
            />

            <Faq
              startIcon={NumberSquareFour}
              question='What are my payment options?'
              answer='For Team plans and Business plans purchased through workspace settings, payments are processed via credit card. Business and Enterprise plans purchased through our sales team can be billed annually, with payment options including purchase order (PO), ACH, wire transfer, or check. Contact our sales team for additional details.'
            />

            <Faq
              startIcon={NumberSquareFive}
              question='What happens if I exceed my usage limits?'
              answer='If your upload exceeds the record limits of your plan, you’ll still have access to your maps, and your data will remain intact. However, you’ll be notified of the overage and won’t be able to add new data until you upgrade to a higher plan.'
            />
          </Faqs>
        </Stack>
      </Stack>
    </Container>
  )
}
