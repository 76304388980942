import { Box, Button, Divider, Paper, Stack, Typography } from '@mui/material'

export function BillingTab(props) {
  const {} = props

  return (
    <Stack
      component={Paper}
      sx={{
        p: 4,
        gap: 4,
        borderRadius: 2,
        flexWrap: 'wrap'
      }}
      direction={{
        sm: 'column',
        md: 'row'
      }}
    >
      <Typography
        flex={1}
        variant='next:h6'
        fontWeight={700}
      >
        Plans & Billing
      </Typography>

      <Stack
        divider={<Divider flexItem />}
        flex={2}
        sx={{
          '> *.BillingItem': {
            py: 2
          },
          '> *:first-child': {
            pt: 0
          },
          '> *:last-child': {
            pb: 0
          }
        }}
      >
        <BillingItem
          title='Plan'
          subtitle='Team'
          action={
            <Button
              color='inherit'
              disableElevation
            >
              Update subscription
            </Button>
          }
        />

        <BillingItem
          title='Paid members'
          subtitle='Update paid members'
          action={
            <Button
              color='inherit'
              disableElevation
            >
              Manage members
            </Button>
          }
        />

        <BillingItem
          title='Billing period'
          subtitle='Update billing period'
          action={
            <Button
              color='inherit'
              disableElevation
            >
              Change period
            </Button>
          }
        />

        <BillingItem
          title='Payment methods'
          subtitle='Update payment methods'
          action={
            <Button
              color='inherit'
              disableElevation
            >
              Manage payment methods
            </Button>
          }
        />

        <BillingItem
          title='Invoices'
          subtitle='View all invoices'
          action={
            <Button
              color='inherit'
              disableElevation
            >
              Invoice history
            </Button>
          }
        />
      </Stack>
    </Stack>
  )
}

function BillingItem(props) {
  const { title, subtitle, action } = props

  return (
    <Stack
      className='BillingItem'
      direction='row'
      alignItems={{
        sm: 'flex-start',
        md: 'space-between'
      }}
      flexDirection={{
        sm: 'column',
        md: 'row'
      }}
      gap={2}
    >
      <Stack
        justifyContent='space-between'
        flex={1}
      >
        <Typography variant='next:subtitle1'>{title}</Typography>
        <Typography
          variant='next:subtitle2'
          color='text.secondary'
        >
          {subtitle}
        </Typography>
      </Stack>
      <Box>{action}</Box>
    </Stack>
  )
}
