import {
  Box,
  Button,
  Chip,
  Container,
  Paper,
  Stack,
  SvgIcon,
  Typography
} from '@mui/material'
import { Buildings, Check, Stack as StackIcon } from '@phosphor-icons/react'
import NextLink from 'next/link'
import React from 'react'
import { getSignInLink, hashMD5 } from 'utils'

import ringEffect from '~ui-components/utilities/ringEffect'

export function Section1() {
  return (
    <Container
      maxWidth='xl'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        py: 8,
        gap: 8
      }}
    >
      <Typography
        variant='next:h2'
        component='h1'
        fontWeight={700}
        textAlign='center'
      >
        Simple, Transparent Pricing for Every Business
      </Typography>

      <Box
        sx={{
          display: 'grid',
          gap: 4,
          gridTemplateColumns: 'repeat(auto-fill, minmax(320px, 1fr))',
          justifyItems: 'center',
          '> *': {}
        }}
      >
        <PlanCard
          type='free'
          description='Perfect for individuals and small projects looking to explore Mapstack’s core features.'
          price={
            <PlanPrice
              annualFee='free'
              monthlyFee='free'
            />
          }
          action={
            <PlanActionButton onClick={() => {}}>Try for free</PlanActionButton>
          }
          includes={
            <PlanIncludes
              title='Free includes:'
              items={[
                'Unlimited maps',
                'Unlimited geocodes',
                '1,000 records per dataset',
                'Invite 10 guests'
              ]}
            />
          }
        />

        <PlanCard
          highlight='Most popular'
          type='team'
          description='Designed for growing teams that need to collaborate and share insights with ease.'
          price={
            <PlanPrice
              currency='$'
              annualFee={19}
              annualFeeDescription='Per seat/month billed annually'
              monthlyFee={24}
              monthlyFeeDescription='billed monthly'
            />
          }
          action={
            <PlanActionButton
              color='primary'
              onClick={() => {}}
            >
              Try for free
            </PlanActionButton>
          }
          includes={
            <PlanIncludes
              title='Everything in free, plus:'
              items={[
                '10,000 records per dataset',
                'Invite 100 guests',
                'Census data',
                'Point of interest data'
              ]}
            />
          }
        />

        <PlanCard
          type='business'
          description='Ideal for organizations managing larger datasets and requiring advanced tools.'
          price={
            <PlanPrice
              currency='$'
              annualFee={39}
              annualFeeDescription='Per seat/month billed annually'
              monthlyFee={49}
              monthlyFeeDescription='billed monthly'
            />
          }
          action={
            <PlanActionButton onClick={() => {}}>Try for free</PlanActionButton>
          }
          includes={
            <PlanIncludes
              title='Everything in team, plus:'
              items={[
                '100,000 records per dataset',
                'Invite 250 guests',
                'Premium geocoding',
                'Satellite imagery'
              ]}
            />
          }
        />

        <PlanCard
          type='enterprise'
          description='Tailored for large-scale operations with custom needs, integrations, and dedicated support.'
          price={
            <PlanPrice
              annualFee={
                <Buildings
                  weight='duotone'
                  size='5rem'
                />
              }
              monthlyFee='Custom pricing'
            />
          }
          action={
            <PlanActionButton onClick={() => {}}>
              Contact sales
            </PlanActionButton>
          }
          includes={
            <PlanIncludes
              title='Everything in business, plus:'
              items={[
                '500,000 records per dataset',
                'Advanced security & controls',
                'SAML SSO',
                'Audit log'
              ]}
            />
          }
        />
      </Box>
    </Container>
  )
}

export function PlanCard(props) {
  const {
    variant = 'elevation',
    highlight,
    icon,
    type,
    label,
    description,
    price,
    action,
    includes
  } = props

  return (
    <Stack
      sx={[
        { position: 'relative', borderRadius: 2 },

        (theme) =>
          highlight
            ? ringEffect({
                ringColor: theme.palette.primary.main,
                ringWidth: '3px',
                borderRadius: 2
              })
            : {
                mt: '42px'
              }
      ]}
    >
      {highlight && (
        <Box
          px={2}
          height={42}
          alignContent='center'
          bgcolor='primary.main'
          sx={(theme) => ({
            borderTopLeftRadius: theme.shape.borderRadius * 2,
            borderTopRightRadius: theme.shape.borderRadius * 2
          })}
        >
          <Typography
            variant='next:subtitle1'
            color='primary.contrastText'
            fontWeight={600}
          >
            {highlight}
          </Typography>
        </Box>
      )}

      <Stack
        component={Paper}
        {...(variant === 'outlined' ? { variant: 'outlined' } : {})}
        {...(variant === 'elevation' ? { variant: 'elevation' } : {})}
        borderRadius={2}
        p={3}
        sx={[
          variant === 'elevation' && {
            boxShadow: 8
          },
          highlight && { borderTopLeftRadius: 0, borderTopRightRadius: 0 }
        ]}
        spacing={4}
      >
        {icon}
        <Stack
          direction='row'
          alignItems='center'
          spacing={1}
        >
          <Typography
            variant='next:h5'
            fontWeight={700}
            textTransform='capitalize'
          >
            {type}
          </Typography>
          {label && (
            <Chip
              label={label}
              color='primary'
              size='small'
            />
          )}
        </Stack>
        {description && (
          <Typography
            variant='next:subtitle1'
            component='p'
            height={120}
          >
            {description}
          </Typography>
        )}
        {price}
        {action}
        {includes}
      </Stack>
    </Stack>
  )
}

export function PlanIcon() {
  return (
    <SvgIcon
      inheritViewBox
      component={StackIcon}
      weight='duotone'
      sx={{
        fontSize: 36
      }}
    />
  )
}

export function PlanPrice(props) {
  const {
    currency,
    annualFee,
    annualFeeDescription,
    monthlyFee,
    monthlyFeeDescription
  } = props

  return (
    <Stack
      direction='column'
      height={120}
    >
      {annualFee === 'free' && monthlyFee === 'free' ? (
        <Typography
          variant='next:h1'
          component='h2'
          fontWeight={500}
          fontSize='5rem'
          letterSpacing='tighter'
          height={96}
        >
          Free
        </Typography>
      ) : (
        <>
          <Stack
            direction='row'
            gap={1}
            alignItems='center'
            height={96}
          >
            {!React.isValidElement(annualFee) ? (
              <Typography
                variant='next:h1'
                component='h2'
                fontWeight={500}
                fontSize='5rem'
                letterSpacing='tighter'
              >
                {currency}
                {annualFee}
              </Typography>
            ) : (
              annualFee
            )}
            {annualFeeDescription && (
              <Typography
                variant='next:subtitle2'
                component='p'
                fontWeight={600}
                color='text.secondary'
                lineHeight={1.2}
              >
                {annualFeeDescription}
              </Typography>
            )}
          </Stack>
          <Typography
            variant='next:subtitle2'
            component='p'
            fontWeight={600}
            color='text.secondary'
          >
            {currency}
            {monthlyFee} {monthlyFeeDescription}
          </Typography>
        </>
      )}
    </Stack>
  )
}

export function PlanSimplePrice(props) {
  const { fee, currency, feeDescription } = props

  return (
    <Stack
      direction='row'
      gap={1}
      alignItems='center'
      height={56}
    >
      {!React.isValidElement(fee) ? (
        <Typography
          variant='next:h1'
          component='h2'
          fontWeight={500}
          fontSize='3rem'
          letterSpacing='tighter'
        >
          {currency}
          {fee}
        </Typography>
      ) : (
        fee
      )}
      {feeDescription && (
        <Typography
          variant='next:subtitle2'
          component='p'
          fontWeight={600}
          color='text.secondary'
          lineHeight={1.2}
        >
          {feeDescription}
        </Typography>
      )}
    </Stack>
  )
}

export function PlanActionButton(props) {
  const { children, ...rest } = props
  return (
    <Button
      component={NextLink}
      href={getSignInLink()}
      size='large'
      color='inherit'
      fullWidth
      disableElevation
      {...rest}
    >
      {children}
    </Button>
  )
}

export function PlanUpgradeButton(props) {
  return (
    <Button
      variant='contained'
      size='medium'
      color='inherit'
      fullWidth
      disableElevation
      {...props}
    >
      Upgrade
    </Button>
  )
}

export function PlanIncludes(props) {
  const { title, items, ...rest } = props
  return (
    <Stack
      direction='column'
      {...rest}
    >
      <Typography
        variant='next:subtitle1'
        component='p'
      >
        {title}
      </Typography>
      <Stack
        component='ul'
        p={0}
        m={0}
        mt={2}
        gap={1.5}
      >
        {items.map((item, index) => (
          <Stack
            key={`Include-${hashMD5(item)}`}
            component='li'
            direction='row'
            gap={1}
            alignItems='center'
          >
            <Check
              size={18}
              weight='bold'
            />
            <Typography variant='next:subtitle2'>{item}</Typography>
          </Stack>
        ))}
      </Stack>
    </Stack>
  )
}
