import bbox from '@turf/bbox'
import centroid from '@turf/centroid'
import { FeatureCollection } from 'geojson'
import _ from 'lodash'
import { getNanoId } from '~utils/functions/getNanoId'
import { getAllGeojsonFeatureProperties } from '~utils/geojson/getAllGeojsonFeatureProperties'
import { getTypeOfEachProperties } from '~utils/geojson/getTypeOfEachProperties'
import { VISUALISATION } from '~utils/types/VisualisationType'
import { MapData } from '~utils/types/map'

const getPropertiesConfigs = (geojsonProperties) => {
  return geojsonProperties.map((property) => {
    return {
      isTitle: false,
      isDefaultVisualisation: false,
      name: property.name,
      isAggregate: false,
      type: property.type,
      isPercentage: false,
      visualisation: VISUALISATION.NONE
    }
  })
}

type ConstructMapDataInputOptions = {
  id?: string
  dataId: string
}

const constructMapData = (
  geojson: FeatureCollection,
  options: ConstructMapDataInputOptions
): MapData => {
  const { id, dataId } = options

  // INFO: Map geojson features' properties to structured map properties
  const arrayOfProperties = getAllGeojsonFeatureProperties(geojson)
  const geojsonProperties = _.values(getTypeOfEachProperties(arrayOfProperties))
  const properties = getPropertiesConfigs(geojsonProperties)

  return {
    id: id || getNanoId(),
    name: 'Default Map Name',
    dataId,
    bbox: bbox(geojson),
    centroid: centroid(geojson).geometry.coordinates,
    properties
  }
}

export { constructMapData }
