import { Button, ButtonProps, SvgIcon } from '@mui/material'
import UserCircle from '@untitled-ui/icons-react/build/esm/UserCircle'
import PropTypes from 'prop-types'

const SignInButton = ({
  variant = 'outlined',
  onClick = () => {},
  ...rest
}) => {
  return (
    <Button
      variant={variant as ButtonProps['variant']}
      data-testid='SignInButton'
      onClick={onClick}
      startIcon={
        <SvgIcon>
          <UserCircle />
        </SvgIcon>
      }
      sx={{
        whiteSpace: 'nowrap',
        minWidth: 'fit-content'
      }}
      {...rest}
    >
      Sign in
    </Button>
  )
}

SignInButton.propTypes = {
  onClick: PropTypes.func
}

export { SignInButton }
export default SignInButton
