import {
  Box,
  Button,
  ButtonBase,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Theme,
  Typography,
  useMediaQuery
} from '@mui/material'

import type { FC, ReactNode } from 'react'

import {
  Dropdown,
  DropdownMenu,
  DropdownTrigger
} from '~ui-components/components/atoms/Dropdown'
import { RouterLink } from '~ui-components/components/atoms/RouterLink'

interface NavItemProps {
  active?: boolean
  disabled?: boolean
  external?: boolean
  dropdown?: boolean
  icon?: ReactNode
  label?: ReactNode
  path?: string
  title?: string
  button?: boolean
  children?: ReactNode
}

export const NavItem: FC<NavItemProps> = (props) => {
  const {
    active,
    disabled,
    external,
    dropdown,
    icon,
    label,
    path,
    title,
    button,
    children
  } = props

  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))

  // With dropdown
  if (children) {
    if (lgUp && dropdown) {
      return (
        <Dropdown>
          <DropdownTrigger>
            <Button
              variant='text'
              color='inherit'
              disabled={disabled}
              startIcon={icon}
            >
              {title}
            </Button>
          </DropdownTrigger>
          <DropdownMenu
            disableScrollLock
            PaperProps={{
              elevation: 8,
              sx: {
                maxWidth: '100%',
                p: 1,
                width: 200
              }
            }}
          >
            {children}
          </DropdownMenu>
        </Dropdown>
      )
    }

    return (
      <Stack
        direction='column'
        gap={0.5}
      >
        <Typography variant='subtitle2'>{title}</Typography>
        {children}
      </Stack>
    )
  }

  const linkProps = path
    ? external
      ? {
          component: 'a',
          href: path,
          target: '_blank'
        }
      : {
          component: RouterLink,
          href: path
        }
    : {}

  // Without dropdown
  if (button) {
    return (
      <ButtonBase
        disabled={disabled}
        sx={{
          alignItems: 'center',
          borderRadius: 1,
          display: 'flex',
          justifyContent: 'flex-start',
          px: '16px',
          py: '6px',
          textAlign: 'left',
          width: '100%',
          ...(active && {
            backgroundColor: 'var(--nav-item-active-bg)'
          }),
          '&:hover': {
            backgroundColor: 'var(--nav-item-hover-bg)'
          }
        }}
        {...linkProps}
      >
        {icon && (
          <Box
            component='span'
            sx={{
              alignItems: 'center',
              color: 'var(--nav-item-icon-color)',
              display: 'inline-flex',
              justifyContent: 'center',
              mr: 2,
              ...(active && {
                color: 'var(--nav-item-icon-active-color)'
              })
            }}
          >
            {icon}
          </Box>
        )}
        <Box
          component='span'
          sx={{
            color: 'var(--nav-item-color)',
            flexGrow: 1,
            fontFamily: (theme) => theme.typography.fontFamily,
            fontSize: 14,
            fontWeight: 600,
            lineHeight: '24px',
            whiteSpace: 'nowrap',
            ...(active && {
              color: 'var(--nav-item-active-color)'
            }),
            ...(disabled && {
              color: 'var(--nav-item-disabled-color)'
            })
          }}
        >
          {title}
        </Box>
        {label && (
          <Box
            component='span'
            sx={{ ml: 1 }}
          >
            {label}
          </Box>
        )}
      </ButtonBase>
    )
  }

  // Leaf
  return (
    <ListItemButton
      disabled={disabled}
      key={title}
      sx={{
        borderRadius: 1,
        px: 1.5,
        py: 0.5
      }}
      {...linkProps}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText
        primary={title}
        primaryTypographyProps={{
          sx: {
            color: 'text.secondary',
            fontSize: 14,
            fontWeight: 500
          }
        }}
      />
    </ListItemButton>
  )
}
