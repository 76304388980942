import Plyr from 'plyr'
import 'plyr/dist/plyr.css'

import { Box } from '@mui/material'
import { useEffect, useRef } from 'react'

type VideoProps = {
  source: Plyr.SourceInfo
} & Plyr.Options

export function Video(props: VideoProps) {
  const { source, ...options } = props

  const plyr = useRef<Plyr | null>(null)

  useEffect(() => {
    return () => {
      if (plyr.current) {
        plyr.current.destroy()
      }
    }
  }, [])

  return (
    <Box
      ref={(element) => {
        plyr.current = new Plyr(element as HTMLElement, {
          ...options
        })

        plyr.current.source = source
      }}
      component='video'
    ></Box>
  )
}
