import { Box, Typography } from '@mui/material'
import Stack from '@mui/material/Stack'
import type { FC } from 'react'
import { useIsMobile } from '~ui-components/hooks'

import {} from '~ui-components/assets/images'

export type LayerType = 'heat-map' | 'boundaries' | 'h3' | 'marker'

interface Option {
  label: string
  value: LayerType
  thumbnailUrl: string
}

const options: Option[] = [
  {
    label: 'Markers',
    value: 'marker',
    thumbnailUrl: `${''}?w=164&h=164&fit=crop&auto=format`
  },
  {
    label: 'Heatmap',
    value: 'heat-map',
    thumbnailUrl: `${''}?w=164&h=164&fit=crop&auto=format`
  },
  {
    label: 'Hexagons',
    value: 'h3',
    thumbnailUrl: `${''}?w=164&h=164&fit=crop&auto=format`
  },
  {
    label: 'Boundaries',
    value: 'boundaries',
    thumbnailUrl: `${''}?w=164&h=164&fit=crop&auto=format`
  }
]

interface OptionsLayoutProps {
  onChange?: (value: LayerType) => void
  value?: LayerType
}

export const LayerTypes: FC<OptionsLayoutProps> = (props) => {
  const isMobile = useIsMobile()
  const { value = 'boundaries', onChange = () => {} } = props

  return (
    <>
      <Typography
        color='text.secondary'
        variant='overline'
      >
        Display
      </Typography>
      <Box
        sx={{
          display: 'grid',
          gap: 1,
          gridTemplateColumns: isMobile
            ? 'repeat(1, 1fr)'
            : 'repeat(2, minmax(0, 168px))'
        }}
      >
        {options.map((option) => (
          <Stack
            key={option.value}
            spacing={1}
          >
            <Stack
              onClick={() => {
                onChange(option.value)
              }}
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark'
                    ? 'neutral.900'
                    : 'background.paper',
                backgroundImage: `url(${option.thumbnailUrl})`,
                borderColor: 'divider',
                borderRadius: 1,
                borderStyle: 'solid',
                borderWidth: 2,
                cursor: 'pointer',
                display: 'flex',
                height: 104,
                ...(option.value === value && {
                  borderColor: 'primary.main'
                })
              }}
            />
            <Typography
              align='center'
              sx={{ fontWeight: 500 }}
              variant='body2'
            >
              {option.label}
            </Typography>
          </Stack>
        ))}
      </Box>
    </>
  )
}
