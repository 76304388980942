import { Box, Container, SvgIcon } from '@mui/material'

import americanLeather from './american-leather.svg'
import pratt from './pratt.svg'
import sanDiego from './san-diego.svg'
import sheffield from './sheffield.svg'
import telus from './telus.svg'

const companies = [
  {
    src: americanLeather,
    alt: 'AMERICAN LEATHER'
  },
  {
    src: pratt,
    alt: 'Pratt'
  },
  {
    src: sanDiego,
    alt: 'The City of SAN DIEGO'
  },
  {
    src: sheffield,
    alt: 'University of Sheffield'
  },
  {
    src: telus,
    alt: 'TELUS'
  }
]

export function Companies() {
  return (
    <Box
      component={Container}
      py={8}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 8,

          '&.Company': {
            flexBasis: 'minmax(168px, 1fr)'
          }
        })}
      >
        {Array.from(companies, (item, index) => (
          <Company
            key={`Company:${index}`}
            {...item}
          />
        ))}
      </Box>
    </Box>
  )
}

export function Company(props) {
  const { src: Component, alt } = props
  return (
    <SvgIcon
      component={Component}
      inheritViewBox
      sx={(theme) => ({
        width: 'auto',
        height: 'auto',
        transition: theme.transitions.create('color'),
        color: theme.palette.grey[600],
        '&:hover': {
          color: theme.palette.grey[900]
        }
      })}
      title={alt}
      alt={alt}
    ></SvgIcon>
  )
}
