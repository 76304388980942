import { Box, Divider, SvgIcon, Tab, Tabs } from '@mui/material'
import NextLink from 'next/link'
import { useQueryParams } from 'ssr/router'
import { getWorkspaceLink } from 'utils'

import {
  ArrowFatUp,
  CreditCard,
  Gear,
  MapTrifold,
  User
} from '@phosphor-icons/react'
import Skeleton from '~ui-components/components/atoms/Skeleton'

export enum WORKSPACE_TAB {
  MAPS = 'maps',
  MEMBERS = 'members',
  SETTINGS = 'settings',
  BILLING = 'billing',
  UPGRADE = 'upgrade'
}

const WorkspaceTabBar = (props) => {
  const {
    canAccessWorkspaceMember,
    canUpdateWorkspaceSettings = false,
    totalMaps = 0,
    totalMembers = 0,
    loading = false
  } = props

  const { workspaceId, workspaceName, tab } = useQueryParams()

  return loading ? (
    <Box
      display='flex'
      flexDirection='row'
      flexWrap='nowrap'
      gap={1}
      sx={{
        overflowX: 'hidden'
      }}
    >
      {Array.from({ length: 4 }).map((_, index) => {
        return (
          <Skeleton
            loading
            variant='square'
            key={index}
            width={122}
            height={40}
          />
        )
      })}
    </Box>
  ) : (
    <Box>
      <Tabs
        data-testid='WorkspaceTabBar'
        indicatorColor='primary'
        scrollButtons='auto'
        sx={{ px: 3 }}
        textColor='primary'
        value={tab || WORKSPACE_TAB.MAPS}
        variant='scrollable'
      >
        <Tab
          label={totalMaps ? `Maps (${totalMaps})` : 'Maps'}
          value={WORKSPACE_TAB.MAPS}
          icon={
            <SvgIcon
              inheritViewBox
              component={MapTrifold}
              weight='duotone'
            />
          }
          component={NextLink}
          href={getWorkspaceLink({
            workspaceId,
            workspaceName,
            tab: WORKSPACE_TAB.MAPS,
            page: 1
          })}
        />

        {canAccessWorkspaceMember && (
          <Tab
            label={totalMembers ? `People (${totalMembers})` : 'People'}
            value={WORKSPACE_TAB.MEMBERS}
            icon={
              <SvgIcon
                inheritViewBox
                component={User}
                weight='duotone'
              />
            }
            component={NextLink}
            href={getWorkspaceLink({
              workspaceId,
              workspaceName,
              tab: WORKSPACE_TAB.MEMBERS,
              page: 1
            })}
          />
        )}

        {canUpdateWorkspaceSettings && (
          <Tab
            label='Settings'
            value={WORKSPACE_TAB.SETTINGS}
            icon={
              <SvgIcon
                inheritViewBox
                component={Gear}
                weight='duotone'
              />
            }
            component={NextLink}
            href={getWorkspaceLink({
              workspaceId,
              workspaceName,
              tab: WORKSPACE_TAB.SETTINGS,
              page: 1
            })}
          />
        )}

        <Tab
          label='Upgrade'
          value={WORKSPACE_TAB.UPGRADE}
          icon={
            <SvgIcon
              inheritViewBox
              component={ArrowFatUp}
              weight='duotone'
            />
          }
          component={NextLink}
          href={getWorkspaceLink({
            workspaceId,
            workspaceName,
            tab: WORKSPACE_TAB.UPGRADE
          })}
        />

        <Tab
          label='Billing'
          value={WORKSPACE_TAB.BILLING}
          icon={
            <SvgIcon
              inheritViewBox
              component={CreditCard}
              weight='duotone'
            />
          }
          href={getWorkspaceLink({
            workspaceId,
            workspaceName,
            tab: WORKSPACE_TAB.BILLING
          })}
        />
      </Tabs>
      <Divider />
    </Box>
  )
}

export { WorkspaceTabBar }
