import {
  Button,
  Container,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material'
import { Check } from '@phosphor-icons/react'
import NextLink from 'next/link'
import { getSignInLink } from 'utils'
import { Companies } from '../Home/Companies'

export function Section2() {
  return (
    <Stack
      direction='column'
      gap={8}
    >
      <Typography
        variant='next:h2'
        component='h2'
        fontWeight={700}
        textAlign='center'
      >
        Plans and features
      </Typography>
      <Companies />
      <FeaturesPerPlan />
    </Stack>
  )
}

function FeaturesPerPlan() {
  return (
    <Container>
      <PlanComparisonTable />
    </Container>
  )
}

export function PlanComparisonTable(props) {
  const { hideAction = false } = props
  return (
    <Paper
      sx={{ width: '100%', overflow: 'hidden', borderRadius: 2 }}
      variant='outlined'
    >
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell sx={{ width: '36ch' }} />
              <TableCell>
                <PlanHeader
                  title='Free'
                  hideAction={hideAction}
                />
              </TableCell>
              <TableCell>
                <PlanHeader
                  title='Team'
                  color='primary'
                  hideAction={hideAction}
                />
              </TableCell>
              <TableCell>
                <PlanHeader
                  title='Business'
                  hideAction={hideAction}
                />
              </TableCell>
              <TableCell>
                <PlanHeader
                  title='Enterprise'
                  hideAction={hideAction}
                />
              </TableCell>
            </TableRow>

            <RowGroup name='Fundamental'>
              <RowItems
                name='Maps'
                items={['Unlimited', 'Unlimited', 'Unlimited', 'Unlimited']}
              />
              <RowItems
                name='Geocodes'
                items={['Unlimited', 'Unlimited', 'Unlimited', 'Unlimited']}
              />
            </RowGroup>

            <RowGroup name='Private maps'>
              <RowItems
                name='Records per dataset'
                items={['1,000', '10,000', '100,000', '500,000']}
              />

              <RowItems
                name='Guests'
                items={['10', '100', '250 ', 'Unlimited']}
              />
            </RowGroup>

            <RowGroup name='Community maps'>
              <RowItems
                name='Records per dataset'
                items={['50,000', '50,000', '50,000', '50,000']}
              />
            </RowGroup>

            <RowGroup name='Visualizations'>
              <RowItems
                name='Points'
                items={[true, true, true, true]}
              />
              <RowItems
                name='Clustered points'
                items={[true, true, true, true]}
              />
              <RowItems
                name='Category points'
                items={[true, true, true, true]}
              />
              <RowItems
                name='Boundary aggregates'
                items={[true, true, true, true]}
              />
              <RowItems
                name='Hexagons aggregates'
                items={[true, true, true, true]}
              />
              <RowItems
                name='Heatmaps'
                items={[true, true, true, true]}
              />
              <RowItems
                name='Table view'
                items={[true, true, true, true]}
              />
            </RowGroup>

            <RowGroup name='Map tools'>
              <RowItems
                name='Data filters'
                items={[true, true, true, true]}
              />

              <RowItems
                name='Address search'
                items={[true, true, true, true]}
              />

              <RowItems
                name='Attribute search'
                items={[true, true, true, true]}
              />
            </RowGroup>

            <RowGroup name='Sharing'>
              <RowItems
                name='Map remix'
                items={[true, true, true, true]}
              />

              <RowItems
                name='Guest access'
                items={[true, true, true, true]}
              />

              <RowItems
                name='Generate infographic'
                items={[true, true, true, true]}
              />

              <RowItems
                name='Embed map'
                items={[true, true, true, true]}
              />
            </RowGroup>

            <RowGroup name='Premium data (coming soon)'>
              <RowItems
                name='Point of interest'
                items={[false, true, true, true]}
              />

              <RowItems
                name='Clustered points'
                items={[false, true, true, true]}
              />

              <RowItems
                name='Census data (US/UK)'
                items={[false, true, true, true]}
              />

              <RowItems
                name='Premium geocoder'
                items={[false, true, true, true]}
              />

              <RowItems
                name='Satellite imagery'
                items={[false, true, true, true]}
              />
            </RowGroup>

            <RowGroup name='Security & Compliance'>
              <RowItems
                name='Advanced security & controls'
                items={[false, false, false, true]}
              />

              <RowItems
                name='SAML SSO'
                items={[false, false, false, true]}
              />

              <RowItems
                name='Audit log'
                items={[false, false, false, true]}
              />
            </RowGroup>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

function PlanHeader(props) {
  const { color = 'inherit', title, hideAction = false } = props
  return (
    <Stack
      direction='column'
      spacing={4}
      m={1}
    >
      <Typography
        variant='next:subtitle1'
        textAlign='center'
        textTransform='capitalize'
        fontWeight={600}
      >
        {title}
      </Typography>
      {!hideAction && (
        <Button
          component={NextLink}
          href={getSignInLink()}
          disableElevation
          size='large'
          color={color}
        >
          Try for free
        </Button>
      )}
    </Stack>
  )
}

function RowGroup(props) {
  const { name, children } = props
  return (
    <>
      <TableRow>
        <TableCell>
          <Typography
            variant='next:subtitle1'
            fontWeight={600}
          >
            {name}
          </Typography>
        </TableCell>
      </TableRow>
      {children}
    </>
  )
}

function RowItems(props) {
  const { name, items = [] } = props
  return (
    <TableRow>
      <TableCell>
        <Typography variant='next:subtitle2'>{name}</Typography>
      </TableCell>
      {items.map((item, index) => (
        <TableCell
          key={`${String(name).replace(/s/, '')}-${index}`}
          align='center'
        >
          {typeof item === 'string' && (
            <Typography variant='next:subtitle2'>{item}</Typography>
          )}
          {typeof item === 'boolean' && item === true && (
            <Check weight='bold' />
          )}
        </TableCell>
      ))}
    </TableRow>
  )
}
