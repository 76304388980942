import {
  Box,
  Button,
  Chip,
  Container,
  Paper,
  Stack,
  Typography
} from '@mui/material'
import NextLink from 'next/link'
import { getSignInLink } from 'utils'

import { Video } from '~ui-components/components/atoms/Video'
import ringEffect from '~ui-components/utilities/ringEffect'

export function Section1() {
  return (
    <Box
      component={Container}
      py={8}
    >
      <Stack
        spacing={6}
        alignItems='center'
      >
        <Stack
          spacing={4}
          alignItems='center'
        >
          <Chip
            label='Geocoder & Map Maker'
            size='medium'
          />
          <Typography
            component='h1'
            variant='next:h1'
            fontWeight={800}
            textAlign='center'
          >
            More Than Just a Geocoder
            <br />
            Visualize Location Data Instantly!
          </Typography>
        </Stack>

        <Typography
          component='p'
          variant='next:h5'
          textAlign='center'
          color='text.secondary'
          maxWidth='88%'
        >
          Mapstack turns your address data into stunning, interactive maps. See
          your locations come to life and uncover the insights hiding in plain
          sight.
        </Typography>

        <Button
          component={NextLink}
          href={getSignInLink()}
          variant='contained'
          color='primary'
          size='large'
        >
          Sign up for free
        </Button>

        <Box
          component={Paper}
          variant='elevation'
          elevation={8}
          sx={(theme) => ({
            width: '100%',
            height: '100%',
            maxHeight: '600px',
            aspectRatio: '16/9',
            borderRadius: 2,
            overflow: 'hidden',
            '&:hover': ringEffect({
              ringColor: theme.palette.primary.main,
              ringWidth: '4px',
              borderRadius: 2
            }),
            '--plyr-color-main': theme.palette.primary.main
          })}
        >
          <Video
            muted
            autoplay
            loop={{
              active: true
            }}
            source={{
              type: 'video',
              sources: [
                {
                  src: `${process.env.MS_ASSETS_URL}/sales-demo-2.mp4`,
                  type: 'video/mp4'
                }
              ]
            }}
          />
        </Box>
      </Stack>
    </Box>
  )
}
