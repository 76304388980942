import _ from 'lodash'
import { typeConversion } from '~utils/geojson/typeConversion'

const convertPropertiesType = (featureProperties) => {
  return _.chain(featureProperties).map(typeConversion).value()
}

const getTypeOfEachProperties = (arrayOfProperties) => {
  const geojsonProperties = arrayOfProperties.reduce((result, properties) => {
    const typeArray = convertPropertiesType(properties)
    typeArray.forEach((convertedType) => {
      const { name } = convertedType
      // INFO: mapping type to properties to array
      // 1. if the property type is not exist, then create the property type
      if (!result[name]) {
        result[name] = convertedType
        return
      }
      // 2. mapping type to properties to text if the converted property type is text
      if (result[name].type === 'text') return
      if (convertedType.type === 'text') {
        result[name] = convertedType
        return
      }
      // 3. if the property type is exist, then check the type object
      if (result[name].type === 'object' && convertedType.type !== 'object') {
        result[name] = convertedType
      }
    })

    return result
  }, {})

  return geojsonProperties
}

export { getTypeOfEachProperties }
