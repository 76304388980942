import { Box, Button, Container, Stack, Typography } from '@mui/material'
import NextLink from 'next/link'
import { getSignInLink } from 'utils'

export function Section7() {
  return (
    <Container
      component={Box}
      py={8}
    >
      <Stack
        spacing={6}
        alignItems='center'
      >
        <Typography
          component='h2'
          variant='next:h2'
          fontWeight={700}
          textAlign='center'
        >
          Mapstack: Insights Made Effortless
        </Typography>
        <Typography
          component='p'
          variant='next:h5'
          color='text.secondary'
          textAlign='center'
          maxWidth='80ch'
        >
          Mapstack turns raw address data into actionable, interactive maps in
          seconds—no technical skills required. Gain instant clarity, save time,
          and make smarter decisions without juggling tools or struggling with
          complex workflows.
        </Typography>
        <Button
          component={NextLink}
          href={getSignInLink()}
          variant='contained'
          color='primary'
          size='large'
        >
          Sign up for free
        </Button>
      </Stack>
    </Container>
  )
}
