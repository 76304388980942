const MS_PREFIX_REGEX = /^ms_/i

const isMapstackProperty = (property) =>
  MS_PREFIX_REGEX.test(property) ||
  property?.startsWith('ENR_') ||
  property === 'id' ||
  // INFO: x and y are used as coordinates in the geojson features' properties
  ['x', 'y'].includes(property?.toLowerCase())

export { isMapstackProperty }
