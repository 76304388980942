import {
  Box,
  Container,
  Divider,
  Paper,
  Stack,
  Typography
} from '@mui/material'
import NextImage from 'next/image'
import { MapstackUserCases } from '~ui-components/components/organisms/MapstackUseCases'

import image1 from './section-5-1.png'
import image2 from './section-5-2.png'
import image3 from './section-5-3.png'

export function Section5() {
  return (
    <Container
      maxWidth='xl'
      component={Paper}
      variant='elevation'
      elevation={8}
      sx={{
        borderRadius: 4
      }}
    >
      <Stack
        direction='column'
        spacing={8}
        my={8}
      >
        <Typography
          variant='next:h2'
          component='h2'
          textAlign='center'
          fontWeight={700}
        >
          How it works
        </Typography>

        <Stack
          direction='row'
          gap={4}
          flexWrap='wrap'
          justifyContent='center'
        >
          <HowItWorkCard
            image={image1}
            title='Upload Your Data'
            alt='Upload Your Data'
            description='Simply drag and drop your address data in a spreadsheet. No need for complex formats—Mapstack handles the rest.'
          />

          <HowItWorkCard
            image={image2}
            title='Get Your Map'
            alt='Get Your Map'
            description='Your data is automatically geocoded and transformed into an interactive map. See your locations come to life without the hassle of juggling tools.'
          />

          <HowItWorkCard
            image={image3}
            title='Analyze and Act'
            alt='Analyze and Act'
            description='Explore patterns, trends, and insights directly on your map. Make smarter decisions for campaigns, sales, and expansion with ease.'
          />
        </Stack>

        <Stack spacing={8}>
          <Typography
            component='h2'
            variant='next:h3'
            fontWeight={700}
            textAlign='center'
          >
            <s>Boring Spreadsheets</s> Amazing Maps
          </Typography>
          <MapstackUserCases />
        </Stack>
      </Stack>
    </Container>
  )
}

function HowItWorkCard(props) {
  const { title, description, image, alt } = props
  return (
    <Box
      width='100%'
      maxWidth={420}
      component={Paper}
      variant='elevation'
      elevation={8}
      borderRadius={3}
    >
      <Typography
        variant='next:h5'
        component='h3'
        textAlign='left'
        fontWeight={700}
        p={3}
      >
        {title}
      </Typography>
      <Divider />
      <Stack
        p={3}
        spacing={3}
      >
        <Box
          component={Paper}
          variant='outlined'
          sx={{
            position: 'relative',
            aspectRatio: '16/9',
            width: '100%',
            fontStyle: 'italic',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 2,
            overflow: 'hidden'
          }}
        >
          <NextImage
            src={image}
            alt={alt}
            fill
          />
        </Box>
        <Typography
          component='p'
          variant='next:subtitle1'
          textAlign='left'
        >
          {description}
        </Typography>
      </Stack>
    </Box>
  )
}
