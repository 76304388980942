import { App as Drawer } from 'map-create-drawer'
import { useState } from 'react'
import { MapCreateButton as Button } from 'ui-components'

function MapCreateButton(props) {
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Button
        {...props}
        onClick={handleClick}
      >
        Create map
      </Button>

      <Drawer
        open={open}
        onClose={handleClose}
      />
    </>
  )
}

export { MapCreateButton }
