import {
  Avatar,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Stack,
  SvgIcon,
  Typography
} from '@mui/material'
import { ArrowSquareUp, ListChecks } from '@phosphor-icons/react'
import { useState } from 'react'

import {
  PlanCard,
  PlanIcon,
  PlanIncludes,
  PlanSimplePrice,
  PlanUpgradeButton
} from '~ui-components/components/templates/Pricing/Section.1'
import { PlanComparisonTable } from '~ui-components/components/templates/Pricing/Section.2'

export function UpgradeTab(props) {
  const { onUpgrade } = props

  const [period, setPeriod] = useState<string>('annual')

  const getActiveStyle = (value: string) => {
    return value === period
      ? {
          color: 'primary' as const,
          variant: 'contained' as const
        }
      : {}
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { period = '' } = event.currentTarget.dataset
    setPeriod(period)
  }

  const handleUpgradeClick = (event) => {
    const { period, plan } = event.currentTarget.dataset
    onUpgrade?.(event, { period, plan })
  }

  return (
    <Stack spacing={4}>
      <Card>
        <CardHeader
          title={
            <Stack>
              <Typography
                variant='next:h6'
                fontWeight={700}
              >
                Upgrade to Pro
              </Typography>
              <Typography
                variant='next:subtitle2'
                color='text.secondary'
              >
                You can upgrade and downgrade whenever you want.
              </Typography>
            </Stack>
          }
          avatar={
            <Avatar>
              <SvgIcon
                inheritViewBox
                component={ArrowSquareUp}
                weight='duotone'
              />
            </Avatar>
          }
        />
        <CardContent>
          <Stack>
            <ButtonGroup>
              <Button
                {...getActiveStyle('monthly')}
                data-period='monthly'
                onClick={handleClick}
              >
                Pay monthly
              </Button>
              <Button
                {...getActiveStyle('annual')}
                data-period='annual'
                onClick={handleClick}
              >
                Pay yearly (big discount)
              </Button>
            </ButtonGroup>

            <Stack
              direction='row'
              gap={4}
            >
              <PlanCard
                variant='outlined'
                type='free'
                icon={<PlanIcon />}
                price={
                  period === 'annual' ? (
                    <PlanSimplePrice
                      currency='$'
                      fee={0}
                      feeDescription='per month'
                    />
                  ) : (
                    <PlanSimplePrice
                      currency='$'
                      fee={0}
                      feeDescription='per month'
                    />
                  )
                }
                action={<PlanUpgradeButton sx={{ visibility: 'hidden' }} />}
                includes={
                  <PlanIncludes
                    title='Highlights:'
                    items={[
                      '1,000 locations per map',
                      '5,000 total records',
                      'Unlimited maps',
                      'Unlimited geocodes',
                      'Unlimited users'
                    ]}
                  />
                }
              />

              <PlanCard
                variant='outlined'
                type='starter'
                icon={<PlanIcon />}
                price={
                  period === 'annual' ? (
                    <PlanSimplePrice
                      currency='$'
                      fee={19}
                      feeDescription='per month'
                    />
                  ) : (
                    <PlanSimplePrice
                      currency='$'
                      fee={19}
                      feeDescription='per month'
                    />
                  )
                }
                action={
                  <PlanUpgradeButton
                    data-period={period}
                    data-plan='starter'
                    onClick={handleUpgradeClick}
                  />
                }
                includes={
                  <PlanIncludes
                    title='Highlights:'
                    items={[
                      '5,000 locations per map',
                      '25,000 total records',
                      'Unlimited maps',
                      'Unlimited geocodes',
                      'Unlimited users'
                    ]}
                  />
                }
              />

              <PlanCard
                variant='outlined'
                type='business'
                label='Popular'
                icon={<PlanIcon />}
                price={
                  period === 'annual' ? (
                    <PlanSimplePrice
                      currency='$'
                      fee={49}
                      feeDescription='per month'
                    />
                  ) : (
                    <PlanSimplePrice
                      currency='$'
                      fee={49}
                      feeDescription='per month'
                    />
                  )
                }
                action={
                  <PlanUpgradeButton
                    data-period={period}
                    data-plan='business'
                    color='primary'
                    onClick={handleUpgradeClick}
                  />
                }
                includes={
                  <PlanIncludes
                    title='Highlights:'
                    items={[
                      '20,000 locations per map',
                      '100,000 total records',
                      'Unlimited maps',
                      'Unlimited geocodes',
                      'Unlimited users'
                    ]}
                  />
                }
              />

              <PlanCard
                variant='outlined'
                type='enterprise'
                icon={<PlanIcon />}
                price={
                  period === 'annual' ? (
                    <PlanSimplePrice
                      currency='$'
                      fee={49}
                      feeDescription='per month'
                    />
                  ) : (
                    <PlanSimplePrice
                      currency='$'
                      fee={49}
                      feeDescription='per month'
                    />
                  )
                }
                action={
                  <PlanUpgradeButton
                    data-period={period}
                    data-plan='enterprise'
                    onClick={handleUpgradeClick}
                  />
                }
                includes={
                  <PlanIncludes
                    title='Highlights:'
                    items={[
                      '100,000 locations per map',
                      '500,000 total records',
                      'Unlimited maps',
                      'Unlimited geocodes',
                      'Unlimited users'
                    ]}
                  />
                }
              />
            </Stack>
          </Stack>
        </CardContent>
      </Card>

      <Card>
        <CardHeader
          title={
            <Stack>
              <Typography
                variant='next:h6'
                fontWeight={700}
              >
                Compare all features
              </Typography>
              <Typography
                variant='next:subtitle2'
                color='text.secondary'
              >
                A summary of features by plan
              </Typography>
            </Stack>
          }
          avatar={
            <Avatar>
              <SvgIcon
                inheritViewBox
                component={ListChecks}
              />
            </Avatar>
          }
        />
        <CardContent>
          <PlanComparisonTable hideAction />
        </CardContent>
      </Card>
    </Stack>
  )
}
