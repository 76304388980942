import {
  Avatar,
  Button,
  Divider,
  Drawer,
  IconButton,
  Paper,
  Stack,
  SvgIcon,
  useMediaQuery
} from '@mui/material'
import type { Theme } from '@mui/material/styles/createTheme'
import NextLink from 'next/link'
import { useState, type FC } from 'react'
import { getPricingLink, getWorkspaceLink } from 'utils'

import ChevronLeftDoubleIcon from '@untitled-ui/icons-react/build/esm/ChevronLeftDouble'
import MapPortalIcon from '@untitled-ui/icons-react/build/esm/Globe02'
import Map01Icon from '@untitled-ui/icons-react/build/esm/Map01'
import Menu01Icon from '@untitled-ui/icons-react/build/esm/Menu01'
import Settings01Icon from '@untitled-ui/icons-react/build/esm/Settings01'
import StarIcon from '@untitled-ui/icons-react/build/esm/Star01'
import Tag01Icon from '@untitled-ui/icons-react/build/esm/Tag01'
import User01Icon from '@untitled-ui/icons-react/build/esm/User01'
import Users01Icon from '@untitled-ui/icons-react/build/esm/Users01'

import { SignInButton } from '~ui-components/components/molecules/SignInButton2'
import { SignUpButton } from '~ui-components/components/molecules/SignUpButton'
import { AccountPopover } from '~ui-components/components/organisms/AccountPopover'
import { NavItem } from '../NavItem'

const TOP_NAV_HEIGHT = 64

interface AppNavProps {
  hideSearch?: boolean
  authenticated?: boolean
  slots?: any
  slotProps?: any
}

export const AppNav: FC<AppNavProps> = (props) => {
  const {
    hideSearch,
    authenticated,
    slots: Slots = {},
    slotProps,
    ...rest
  } = props

  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))

  return (
    <>
      <Paper
        variant='outlined'
        component='header'
        sx={{
          borderTop: 'none',
          borderLeft: 'none',
          borderRight: 'none',
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          zIndex: (theme) => theme.zIndex.appBar,
          boxShadow: (theme) => theme.shadows[8]
        }}
        {...rest}
      >
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          sx={{
            minHeight: TOP_NAV_HEIGHT,
            px: 2
          }}
        >
          <Stack
            alignItems='center'
            direction='row'
            spacing={2}
          >
            <Slots.Logo />
          </Stack>
          {!hideSearch && (
            <Stack
              flex={1}
              maxWidth='540px'
              alignItems={lgUp ? 'center' : 'flex-end'}
            >
              <Slots.SearchBar />
            </Stack>
          )}
          <Stack
            direction='row'
            spacing={2}
            ml={1}
          >
            {!lgUp ? (
              <>
                {!authenticated ? (
                  <IconButton onClick={handleOpen}>
                    <SvgIcon>
                      <Menu01Icon />
                    </SvgIcon>
                  </IconButton>
                ) : (
                  <Slots.NavAvatar />
                )}
              </>
            ) : (
              <>
                <Stack direction='row'>
                  {lgUp && !authenticated && <Slots.CommunityItem />}

                  {!authenticated && <PricingButton />}

                  {!authenticated && <SignInButton />}
                </Stack>

                {!authenticated ? (
                  <SignUpButton
                    color='primary'
                    variant='contained'
                  />
                ) : (
                  <Slots.MapCreateButton />
                )}

                {authenticated && <Slots.NavAvatar />}
              </>
            )}
          </Stack>
        </Stack>
      </Paper>

      <Slots.NavDrawer
        authenticated={authenticated}
        open={open}
        onClose={handleClose}
      />
    </>
  )
}

export function NavDrawer(props) {
  const {
    authenticated,
    open,
    onClose,
    slots: Slots = {},
    sx,
    PaperProps = {},
    ...rest
  } = props

  return (
    <Drawer
      anchor='left'
      sx={sx}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: [
          {
            width: '100%',
            maxWidth: '100%'
          },
          ...(Array.isArray(PaperProps.sx) ? PaperProps.sx : [PaperProps.sx])
        ]
      }}
      {...rest}
    >
      <Stack
        alignItems='center'
        direction='row'
        justifyContent='space-between'
        spacing={3}
        sx={{
          px: 2,
          pt: 1
        }}
      >
        <Stack
          alignItems='inherit'
          direction='row'
          spacing={1}
        >
          <Slots.Logo />
        </Stack>

        <IconButton onClick={onClose}>
          <SvgIcon>
            <ChevronLeftDoubleIcon />
          </SvgIcon>
        </IconButton>
      </Stack>
      <Divider
        orientation='horizontal'
        sx={{ mt: 1 }}
      />
      <Stack
        sx={{ flexGrow: 1, p: 2 }}
        alignItems='left'
        direction='column'
      >
        <Stack
          spacing={0.5}
          sx={{
            flexGrow: 1,
            listStyle: 'none',
            m: 0,
            p: 0
          }}
        >
          <Slots.CommunityItem dropdown={false} />
        </Stack>

        <Stack gap={1}>
          {!authenticated && <PricingButton />}
          {!authenticated ? (
            <>
              <SignInButton />
              <SignUpButton
                color='primary'
                variant='contained'
              />
            </>
          ) : (
            <Slots.MapCreateButton />
          )}
        </Stack>
      </Stack>
    </Drawer>
  )
}

export function CommunityItem(props) {
  return (
    <NavItem
      dropdown
      title='Community maps'
      {...props}
    >
      <NavItem
        title='Map portal'
        path='/search'
        icon={
          <SvgIcon>
            <MapPortalIcon />
          </SvgIcon>
        }
      />

      <NavItem
        title='Best of mapstack'
        path='/explore'
        icon={
          <SvgIcon>
            <StarIcon />
          </SvgIcon>
        }
      />

      <NavItem
        title='Tags'
        path='/tags'
        icon={
          <SvgIcon>
            <Tag01Icon />
          </SvgIcon>
        }
      />
    </NavItem>
  )
}

export function NavAvatar(props) {
  const { currentWorkspace, slots } = props

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const open = Boolean(anchorEl)

  return (
    <>
      <Avatar
        sx={{ height: 40, width: 40 }}
        src={currentWorkspace?.logo ?? undefined}
        onClick={handleClick}
      >
        <SvgIcon>
          <User01Icon />
        </SvgIcon>
      </Avatar>

      <AccountPopover
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        slots={slots}
      />
    </>
  )
}

export function LogoutButton(props) {
  return (
    <Button
      fullWidth
      color='inherit'
      variant='text'
      {...props}
    >
      Logout
    </Button>
  )
}

export function WorkspaceItem(props) {
  const { workspaceId, workspaceName } = props
  return (
    <NavItem
      title='Workspace'
      {...props}
    >
      <NavItem
        title='Maps'
        path={getWorkspaceLink({
          workspaceId,
          workspaceName,
          tab: 'maps'
        })}
        icon={
          <SvgIcon>
            <Map01Icon />
          </SvgIcon>
        }
      />

      <NavItem
        title='Members'
        path={getWorkspaceLink({
          workspaceId,
          workspaceName,
          tab: 'members'
        })}
        icon={
          <SvgIcon>
            <Users01Icon />
          </SvgIcon>
        }
      />

      <NavItem
        title='Settings'
        path={getWorkspaceLink({
          workspaceId,
          workspaceName,
          tab: 'settings'
        })}
        icon={
          <SvgIcon>
            <Settings01Icon />
          </SvgIcon>
        }
      />
    </NavItem>
  )
}

export function PricingButton() {
  return (
    <Button
      component={NextLink}
      variant='text'
      color='inherit'
      href={getPricingLink()}
    >
      Pricing
    </Button>
  )
}
