import { Box, Paper, Stack, Typography } from '@mui/material'
import NextImage from 'next/image'

import image1 from './section-3-1.webp'
import image2 from './section-3-2.webp'
import image3 from './section-3-3.webp'

export function Section3() {
  return (
    <Stack
      py={8}
      spacing={8}
    >
      <Typography
        component='h2'
        variant='next:h2'
        fontWeight={700}
        textAlign='center'
        flex={1}
      >
        Powerful for Teams Across Industries
      </Typography>

      <Stack
        px={4}
        gap={4}
        direction='row'
        justifyContent='center'
        flexWrap='wrap'
      >
        <BenefitCard
          image={image1}
          title='Marketers: Target with Precision'
          alt='Marketers: Target with Precision'
          description='Mapstack helps marketers uncover geographic trends, refine audience segmentation, and create high-impact campaigns. Visualize customer hotspots and maximize your ROI with location-based insights.'
        />

        <BenefitCard
          image={image2}
          title='Sales Teams: Sell Smarter'
          alt='Sales Teams: Sell Smarter'
          description='Empower your sales team with interactive maps to analyze territories, identify top-performing regions, and optimize outreach strategies. Close more deals in the right places.'
        />

        <BenefitCard
          image={image3}
          title='Business Owners: Expand with Confidence'
          alt='Business Owners: Expand with Confidence'
          description='Business owners can plan strategic growth by visualizing customer distribution and market potential. Make data-driven decisions to identify the next big opportunity.'
        />
      </Stack>
    </Stack>
  )
}

function BenefitCard(props) {
  const { title, description, image, alt } = props
  return (
    <Stack
      direction='column'
      component={Paper}
      variant='elevation'
      elevation={8}
      spacing={3}
      sx={{ p: 4, width: '100%', maxWidth: '420px', borderRadius: 3 }}
    >
      <Typography
        component='h3'
        variant='next:h5'
        fontWeight={700}
        textAlign='center'
        flexBasis='60px'
      >
        {title}
      </Typography>
      <Typography
        flex={1}
        color='text.secondary'
        variant='next:h6'
        letterSpacing='tight'
        textAlign='center'
      >
        {description}
      </Typography>

      <Box
        component={Paper}
        variant='outlined'
        sx={{
          position: 'relative',
          aspectRatio: '1/1',
          maxHeight: '400px',
          fontStyle: 'italic',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
          borderRadius: 2
        }}
      >
        <NextImage
          src={image}
          fill
          alt={alt}
        />
      </Box>
    </Stack>
  )
}
