import { PageLayout as Layout } from '../PageLayout'
import { Section1 } from './Section.1'
import { Section2 } from './Section.2'
import { Section3 } from './Section.3'

export function Pricing(props) {
  const { slots, slotProps } = props

  return (
    <Layout
      slots={slots}
      slotProps={slotProps}
    >
      <Section1 />
      <Section2 />
      <Section3 />
    </Layout>
  )
}
