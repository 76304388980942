import PostTemplate from '~ui-components/components/templates/PostId'
import type { Post } from '~ui-components/components/templates/PostId/types/blog'
import { PageLayout } from '../PageLayout'

const Post = (props) => {
  const { post, slots, slotProps } = props
  return (
    <PageLayout
      slots={slots}
      slotProps={slotProps}
    >
      <PostTemplate post={post} />
    </PageLayout>
  )
}

export { Post }
export default Post
