import { Button } from '@mui/material'
import NextLink from 'next/link'
import { getSignInLink } from 'utils'

export const SignUpButton = (props) => {
  return (
    <Button
      component={NextLink}
      variant='text'
      data-testid='SignUpButton'
      color='inherit'
      href={getSignInLink()}
      {...props}
    >
      Sign up for free
    </Button>
  )
}
