import { Box, Container, Paper, Stack, Typography } from '@mui/material'
import NextImage from 'next/image'

import image1 from './section-2-1.png'
import image2 from './section-2-2.png'

export function Section2() {
  return (
    <Box
      component={Container}
      py={8}
    >
      <Stack
        component={Paper}
        spacing={6}
        alignItems='center'
        elevation={8}
        sx={{
          p: {
            xs: 4,
            sm: 6
          },
          borderRadius: 4
        }}
      >
        <Typography
          component='h2'
          variant='next:h2'
          fontWeight={700}
          textAlign='center'
        >
          Make Smarter Decisions, Faster
        </Typography>

        <Typography
          component='p'
          variant='next:h6'
          textAlign='center'
          color='text.secondary'
          maxWidth='88%'
        >
          Mapstack transforms raw address data into clear, interactive maps that
          reveal patterns and insights instantly. No more manual analysis—just
          upload your data and take action with confidence.
        </Typography>

        <HighlightCard
          image={image1}
          alt='One Tool, Endless Possibilities'
          title='One Tool, Endless Possibilities'
          description='Stop juggling multiple tools. Mapstack combines geocoding and interactive mapping in a single platform, saving you time and simplifying your workflow.'
        />

        <HighlightCard
          direction='row-reverse'
          image={image2}
          alt='No Tech Skills? No Problem.'
          title='No Tech Skills? No Problem.'
          description='Designed for non-technical users, Mapstack offers intuitive tools that make geocoding and mapping effortless. Start seeing insights without needing a mapping expert.'
        />
      </Stack>
    </Box>
  )
}

function HighlightCard(props) {
  const { direction = 'row', image, alt, title, description } = props
  return (
    <Stack
      direction={direction}
      flexWrap='wrap'
      gap={8}
      justifyContent='center'
    >
      <Box
        sx={{
          position: 'relative',
          aspectRatio: '1/1',
          width: '100%',
          flex: 1,
          maxWidth: 400,
          maxHeight: 400,
          flexBasis: 'clamp(100%, 400px, 100%)'
        }}
      >
        <NextImage
          src={image}
          fill
          alt={alt}
        />
      </Box>
      <Stack
        flex={1}
        flexBasis='30ch'
        spacing={2}
        justifyContent='center'
      >
        <Typography variant='h2'>{title}</Typography>
        <Typography
          color='text.secondary'
          variant='next:h6'
          letterSpacing='tight'
        >
          {description}
        </Typography>
      </Stack>
    </Stack>
  )
}
